<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Pendukung</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>NIK : </label>
                  <input
                    v-model="form.nik_saksi"
                    type="text"
                    readonly
                    class="form-control"
                  />
                  <small
                    class="invalid-feedback"
                    v-html="formValidate.nik_saksi"
                  ></small>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="nama_saksi">Nama: </label>
                  <input
                    v-model="form.nama_saksi"
                    type="text"
                    readonly
                    class="form-control"
                    placeholder="Nama"
                    style="text-transform: uppercase"
                  />
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Jenis Kelamin : </label>
                  <input
                    type="text"
                    v-model="form.jenkel_saksi"
                    class="form-control"
                    readonly
                    style="text-transform: uppercase"
                  />
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Telp/HP : </label>
                  <input
                    v-model="form.hp_saksi"
                    readonly
                    type="number"
                    class="form-control"
                    placeholder="Telp/HP"
                    style="text-transform: uppercase"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label>Alamat: </label>
                  <textarea
                    v-model="form.alamat_saksi"
                    class="form-control"
                    readonly
                    placeholder="Alamat"
                    rows="3"
                    style="text-transform: uppercase"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label>Kecamatan : </label>
                  <input
                    v-model="form.kec_saksi"
                    class="form-control"
                    readonly
                    style="text-transform: uppercase"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label>Desa : </label>
                  <input
                    v-model="form.kel_saksi"
                    class="form-control"
                    readonly
                    style="text-transform: uppercase"
                  />
                </div>
              </div>
              <div class="col-md-12" v-if="formValidate.id_pendukung">
                <div class="alert alert-danger">
                  <h6 v-html="formValidate.id_pendukung"></h6>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_saksi: "",
        nik_saksi: "",
        nama_saksi: "",
        jenkel_saksi: "",
        alamat_saksi: "",
        kab_saksi: "Kab. Tojo Una-Una",
        kec_saksi: "",
        kel_saksi: "",
        tps_saksi: "",
        hp_saksi: "",
      },
      id_pendukung: "",
      loading: false,
      showUserAccess: true,
      formValidate: [],
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.id_pendukung = id;
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/pendukung/detail/" + this.id_pendukung)
        .then((response) => {
          this.form.nik_saksi = response.data.data.nik_pendukung;
          this.form.nama_saksi = response.data.data.nama_pendukung;
          this.form.jenkel_saksi = response.data.data.jenkel_pendukung;
          this.form.alamat_saksi = response.data.data.alamat_pendukung;
          this.form.kab_saksi = response.data.data.kab_pendukung;
          this.form.kec_saksi = response.data.data.kec_pendukung;
          this.form.kel_saksi = response.data.data.kel_pendukung;
          this.form.tps_saksi = response.data.data.tps_pendukung;
          this.form.hp_saksi = response.data.data.hp_pendukung;
          let nama = response.data.data.nama_pendukung;
          nama = nama.split(" ");
          nama = nama[0];
          this.form.username =
            nama.toLowerCase().replace(/\s/g, ".") + ".saksi";
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      formData.append("id_pendukung", this.id_pendukung);
      formData.append("full_name", this.form.nama_saksi);
      formData.append("phone_number", this.form.hp_saksi);

      axios
        .post("/v1/saksi/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.push("/data-saksi/");
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    getTpsOptions() {
      return Array.from({ length: 15 }, (_, index) => {
        const tpsNumber = "TPS " + (index + 1).toString().padStart(2, "0");
        return tpsNumber;
      });
    },
  },
};
</script>
